import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import TitleCopy2 from 'components/TitleCopy2'
import BannerMid from 'components/BannerMid'
import ImageWrap from 'components/ImageWrap'
import GridSimple from 'components/GridSimple'
import { StaticImage } from 'gatsby-plugin-image'

export default function AboutTemplate({ data }) {
  const page = data.wpPage
  const {
    bannerMidAbout,
    gridSimple,
    logosCopy,
    logosCopy2,
    team,
    titleCopy2,
  } = page.template.about

  const customTeamGlobal = {
    title: team.title,
    members: team.members,
  }
  const customTeamLocal = {
    title: team.titleLocal,
    members: team.membersLocal,
  }
  return (
    <Layout
      bodyClassName='page-template page-template-page-builder page-builder page page-id-137 who-we-are app-data index-data singular-data page-data page-137-data page-who-we-are-data page-builder-data'
      page={page}
    >
      <TitleCopy2 content={titleCopy2} />
      <GridSimple content={gridSimple} />
      <LogosCopy content={logosCopy} />
      <GridTeam content={customTeamGlobal} />
      <GridTeam content={customTeamLocal} />
      <BannerMid content={bannerMidAbout} />
      {/* <LogosCopy2 content={logosCopy2} /> */}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...RelatedBlockPage
      ...ContactBlockPage
      template {
        templateName
        ... on WpTemplate_About {
          about {
            titleCopy2 {
              pretitle
              title
              body
            }
            gridSimple {
              title
              items {
                title
                body
              }
            }
            logosCopy {
              text {
                title
                description
                list {
                  number
                  description
                }
              }
            }
            team {
              title
              members {
                image {
                  ...GatsbyImage
                }
                fullName
                linkedInLink
                title
                bio
              }
              titleLocal
              membersLocal {
                image {
                  ...GatsbyImage
                }
                fullName
                linkedInLink
                title
                bio
              }
            }
            bannerMidAbout {
              image {
                desktop {
                  ...GatsbyImage
                }
                mobile {
                  ...GatsbyImage
                }
              }
              pretitle
              title
              body
              pageLink {
                ...PageLinkPageInner
                ...PageLinkPostInner
              }
              pageLinkText
            }
            logosCopy2 {
              text {
                pretitle
                title
                description
              }
            }
          }
        }
      }
    }
  }
`

const LogosCopy = ({ content }) => {
  const { title, description, list } = content.text
  return (
    <section className='logo-copy'>
      <div className='logo-copy__hypercube'></div>
      <div className='logo-copy__wrap'>
        <div className='logo-copy__flex'>
          <div className='logo-copy__flex-item'>
            <div className='logo-copy__logo-container'>
              <picture className='logo-copy__logo'>
                <StaticImage
                  src='../images/logos/2020Q1_Marketing-Measurement-And-Optimization-Solutions_FAKEBADGEE_homepage.jpg'
                  alt=''
                />
              </picture>
            </div>
            <div className='logo-copy__logo-container'>
              <picture className='logo-copy__logo'>
                <StaticImage
                  src='../images/logos/SDA19_Web_Badge_White-03.png'
                  alt='Analytic Partners is the Smart Data Agency of the Year - I-COM'
                />
              </picture>
            </div>
            <div className='logo-copy__logo-container'>
              <StaticImage
                src='../images/logos/WBE_Seal_RGB-700x397-2.png'
                alt='Analytic Partners is a certified women-owned organization'
              />
            </div>
          </div>
          <div className='logo-copy__flex-item'>
            <h3 className='logo-copy__title'>{title}</h3>

            <p className='logo-copy__excerpt'>{description}</p>

            <ul className='logo-copy__list'>
              {list.map((item, i) => (
                <li key={i} className='logo-copy__list-item'>
                  <span className='item__number item__number--top'>
                    {item.number}{' '}
                  </span>
                  <span>{item.description}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

const GridTeam = ({ content }) => {
  const { title, members } = content
  return (
    <section className='bios'>
      <div className='bios__wrap'>
        <div className='bios__flex'>
          <h3 className='bios__title'>{title}</h3>
          {members.map((item, i) => (
            <GridTeamItem key={i} content={item} />
          ))}
          <GridTeamItem isEmpty={true} />
          <GridTeamItem isEmpty={true} />
        </div>
      </div>
    </section>
  )
}
const GridTeamItem = ({ content, isEmpty }) => {
  const { image, fullName, linkedInLink, title, bio } = content || {}
  if (isEmpty) {
    return <article className='bio is-empty' />
  } else {
    return (
      <article className='bio'>
        <div className='bio__wrap'>
          <div className='bio__head-shot'>
            <ImageWrap image={image} />
          </div>

          <h4 className='bio__name'>
            {fullName}{' '}
            {linkedInLink && (
              <a
                href={linkedInLink}
                target='_blank'
                className='bio__linkedin-link'
              >
                <svg
                  className='bio__linkedin-svg'
                  id='sm-linkedin-desktop'
                  viewBox='0 0 20 20'
                >
                  <path d='M20 10c0 5.5-4.5 10-10 10S0 15.5 0 10 4.5 0 10 0s10 4.5 10 10zM7.5 5.2c0-.8-.6-1.5-1.7-1.5-1 0-1.7.6-1.7 1.5 0 .8.6 1.5 1.6 1.5 1.2 0 1.8-.7 1.8-1.5zm-.4 2.7H4.6V15h2.5V7.9zm9.1 3.3c0-2.3-1.1-3.8-2.9-3.8-1 0-1.8.6-2.1 1.5l-.1-1.1H8.6c0 .3.1 1.7.1 1.7v5.4h2.5v-3.8c0-1 .5-1.7 1.2-1.7.8 0 1.3.4 1.3 1.7v3.8h2.5v-3.7z'></path>
                </svg>
              </a>
            )}
          </h4>

          <h5 className='bio__job-title'> {title}</h5>

          <div
            className='bio__biography'
            dangerouslySetInnerHTML={{ __html: bio }}
          />
        </div>
      </article>
    )
  }
}

const LogosCopy2 = ({ content }) => {
  const { pretitle, title, description } = content.text
  return (
    <>
      <section className='multivariate-grid multivariate-grid--horizontal-clip center-content-block'>
        <div className='hypercubes__base hypercubes__base-left'></div>
        <div className='hypercubes__base hypercubes__base-right'></div>
        <div className='center-content-block__wrap'>
          <h6 className='center-content-block__pretitle'>{pretitle}</h6>
          <h2 className='center-content-block__title'>{title}</h2>
          <p className='center-content-block__excerpt'>{description}</p>
          <div className='center-content-block__flex'>
            <div className='center-content-block__partners__logo'>
              <picture className='center-content-block__partners__img'>
                <StaticImage
                  src='../images/logos/logo-googlepartner.png'
                  alt=''
                />
              </picture>
            </div>
            <div className='center-content-block__partners__logo'>
              <picture className='center-content-block__partners__img'>
                <StaticImage
                  src='../images/logos/logo-fbmarketing.png'
                  alt=''
                />
              </picture>
            </div>
            <div className='center-content-block__partners__logo'>
              <picture className='center-content-block__partners__img'>
                <StaticImage
                  src='../images/logos/AmznAd_KO_wht._CB1536862802_-002-3.png'
                  alt=''
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section className='textarea'>
        <div className='textarea__container'>
          <div className='textarea__text'>
            <div id='addedLogos'>
              <ul className='ulLogo'>
                <li className='liLogo'>
                  <StaticImage
                    className='logoPartner'
                    src='../images/logos/logo-liveramp.png'
                    alt='Liveramp'
                  />
                </li>
                <li className='liLogo'>
                  <StaticImage
                    className='logoPartner'
                    src='../images/logos/logo-microsoft.png'
                    alt='Microsoft'
                  />
                </li>
                <li className='liLogo'>
                  <StaticImage
                    className='logoPartner'
                    src='../images/logos/logo-snap.png'
                    alt='Snapchat'
                  />
                </li>
                <li className='liLogo'>
                  <StaticImage
                    className='logoPartner'
                    src='../images/logos/logo-pinterest.png'
                    alt='Pinterest'
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
