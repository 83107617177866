import React from 'react'

export default function GridSimple({ content }) {
  const { title, items } = content
  return (
    <section className='multivariate-grid multivariate-grid--vertical-clip simple-tile-grid simple-tile-grid--grid'>
      <div className='simple-tile-grid__container multivariate-grid__container'>
        {title && <h6 className='simple-tile-grid__pretitle'>{title}</h6>}
        <div className='simple-tile-grid__row multivariate-grid__row'>
          {items.map((item, i) => (
            <GridSimpleItem key={i} content={item} />
          ))}
        </div>
      </div>
    </section>
  )
}

const GridSimpleItem = ({ content }) => {
  const { title, body } = content
  return (
    <div className='simple-tile-grid__column multivariate-grid__column'>
      <div className='content-cta-image-card'>
        <div className='content-cta-image-card__content'>
          <h4 className='content-cta-image-card__title'>{title}</h4>
          <div
            className='content-cta-image-card__excerpt'
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </div>

        <div className='content-cta-image-card__image-cta-container'></div>
      </div>
    </div>
  )
}
